import Storage from "@/util/Storage";
import {GetPhoneCode} from "@/Api/Basics";
import {CodeLogin} from "@/Api/Login";
import {CodeLoginBodyType} from "@/Api/Login/indexType";
import {Toast} from "vant";
import {getUserInfoSuc} from "@/views/Login/Server";


export function getSmsCode(phone: string | number) {
    return Promise.resolve(GetPhoneCode({phone: phone as string, templateCode: 0}).then(res => res.data))
}


export function codeLogin(body: CodeLoginBodyType) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    if (body.phone.length <= 0) {
        Toast("请输入账号")
        return Promise.resolve()
    }
    if (body.smsCode.length <= 0) {
        Toast("请输入验证码")
        return Promise.resolve()
    }
    Toast.loading({message: "登录中~~", duration: 0})
    return (
        new Promise((resolve, reject) => {
            CodeLogin({userId, token}, body).then(res => {
                let {userId, token} = res.data
                Storage.SetAllData && Storage.SetAllData("userId", userId)
                Storage.SetAllData && Storage.SetAllData("token", token)
                resolve(res.data)
            })
        }).then(res => {
            return (
                getUserInfoSuc().then(res => {
                    Toast.clear()
                    return res
                })
            )
        }).catch(cat => {
            Toast.clear()
        })
    )
}
